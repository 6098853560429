$primary: #0f6894;
$primary-shade-1: #137cbd;
$primary-shade-2: #3fa6da;
$primary-low-contrast: #ebf1f5;
$primary-lowest-contrast: #f5f8fa;
$background: #dedede;
$no-contrast: #ffffff;
$secondary-text: #5c7080;
$warm-yellow: rgba(255, 234, 173, 1);
$warmer-yellow: rgb(255, 204, 93);

:root {
  --primary: #0f6894;
  --primary-shade-1: #137cbd;
  --primary-shade-2: #3fa6da;
  --primary-low-contrast: #ebf1f5;
  --primary-lowest-contrast: #f5f8fa;
  --background: #dedede;
  --no-contrast: #ffffff;
  --secondary-text: #5c7080;
  --border: #e1e8ed;
}

$font-size-base: 1rem;

$accordion-button-active-bg: $primary;
$accordion-button-active-color: $no-contrast;

$focus-ring-width: 0;
$focus-ring-opacity: 0;
$zindex-dropdown: 1050;

$list-group-active-bg: $warmer-yellow;

// $body-bg: $background;

// Required
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

$custom-colors: (
  'no-contrast': $no-contrast,
  'white': #fff,
  'primary': $primary,
  'primary-shade-1': $primary-shade-1,
  'primary-shade-2': $primary-shade-2,
  'primary-low-contrast': $primary-low-contrast,
  'primary-lowest-contrast': $primary-lowest-contrast,
  'background': $background,
  'secondary-text': $secondary-text,
);

$theme-colors: map-merge($theme-colors, $custom-colors);
$list-group-active-color: $list-group-color;

// Use bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons.css';

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: var(--background);
}

main {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
}

.audit__layout {
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-gap: 1rem;
  align-items: flex-start;
}

.wordcloud {
  display: flex;
  flex-direction: column;
}

.wordcloud__grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 1rem;
}

.wordcloud__grid-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.125s;
  overflow: hidden;
  min-height: 3.5rem;

  h4 {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    gap: 0.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.small {
    background-color: var(--primary-shade-2);
    grid-column: span 2;

    h4 {
      font-size: 90%;
      flex-direction: column;
      align-items: center;
    }
  }

  &.medium {
    background-color: var(--primary-shade-1);
    grid-column: span 3;

    h4 {
      font-size: 105%;
    }
  }

  &.large {
    background-color: var(--primary);
    grid-column: span 6;

    h4 {
      font-size: 115%;
    }
  }

  &:hover {
    filter: brightness(0.93);
    cursor: pointer;
    transform: scale(1.03);
  }
}

.search-view {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-rows: auto 1fr;
  height: calc(100vh - 70px);

  // With search results in list
  grid-template-columns: 464px 8fr;
  grid-template-areas:
    'searchbar searchbar'
    'searchresults pdf';

  // Without search results in list, just PDF
  &.search-view__hide-results {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    column-gap: 0;
    grid-template-areas:
      'searchbar'
      'pdf';
  }
}

.searchbar {
  grid-area: searchbar;
}

.searchresults {
  grid-area: searchresults;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .search-view__hide-results & {
    display: none;
  }
}

.searchresults em {
  color: var(--primary);
  font-weight: bold;
}

.pdfviewer {
  grid-area: pdf;
  position: relative;
  display: flex;
  flex-direction: column;
}

.search-view__search {
  margin: 0.5rem 0;
}

.form-floating > label {
  max-width: 100%;
}

.bp3-navbar.bp3-dark a {
  text-decoration: none;
  color: var(--no-contrast);
}

div.PdfHighlighter {
  max-width: 651px;
  max-height: 100%;
  height: 100vh;
  overflow-y: scroll;
}

div.PdfHighlighter {
  max-width: 100% !important;

  .Highlight__part {
    background: $warm-yellow !important;
  }

  .Highlight--scrolledTo .Highlight__part {
    background: $warmer-yellow !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}
